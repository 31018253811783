<!--
 * @Author: lg9527 13691080728@126.com
 * @Date: 2024-07-11 22:46:53
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2024-09-19 18:14:12
 * @FilePath: \zky_webVue\src\views\sample\child\importStandard\components\UploadExcel.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-upload
    name="fileInput"
    :headers="{
      Authorization: token ? 'Bearer ' + token : ''
    }"
    :limit="1"
    show-file-list
    :action="uploadUrl"
    accept=".xls,.xlsx"
    :on-progress="onUploadProgress"
    :on-error="onUploadError"
    :on-success="onUploadSuccess"
    :on-exceed="onUploadExceed"
    :on-remove="onUploadRemove">
    <el-button type="primary" @click="onDownloadTpl">标准数据导入(模板)</el-button>
    <el-button>上传<i class="el-icon-upload el-icon--right"></i></el-button>
    <p class="upload-tip">1.点击上传文件，支持xsl，xlsx格式</p>
    <p class="upload-tip">2.上传后系统自动解析匹配字典项</p>
    <p class="upload-tip">3.模版内基本信息(sheet必填)，其他定年分析(sheet)、同位素分析(sheet)、主量元素分析(sheet)、微量元素分析(sheet)根据实际情况填写可以任选一个或多个</p>
  </el-upload>


</template>

<script>
import * as commonApi from '@/api/common'
import setting from '@/setting'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      fileList: []
    }
  },
  computed: {
    ...mapState(['user', 'token']),
    uploadUrl() {
      return `${setting.baseUrl.api}D_Sample/Import`
    }
  },
  // emits: ['uploadSuccess'],
  methods: {
    onDownloadTpl() {
      let url = location.origin + `/static/样品信息(导入模板).xlsx`
      window.open(url, '_blank')
    },
    onUploadProgress() {
      this.loading = true
    },
    onUploadError(err) {
      this.loading = false
    },
    onUploadSuccess(res, file) {
      this.loading = false
      let { status, data, xlszd, message} = res
      if (status) {
        this.$message.success({
          message: '上传成功！'
        })
        this.$emit('uploadSuccess', res)
      } else {    
        // this.$alert(res.message, '质检报告', {
        //   dangerouslyUseHTMLString: true,
        //   customClass: 'custom-alert',
        //   callback: action => {
        //     if (action === 'confirm') {
        //       this.$message({
        //   type: 'info',
        //   message: '确认按钮被点击'
        //       });
        //     }
        //   },
        //   style: {
        //     width: 'auto', // 设置宽度自适应
        //     maxWidth: '90vw' // 设置最大宽度为视口宽度的90%
        //   }
        // });
        // 添加报告下载链接
        const logPath =  res.logpath;
        const logUrl = logPath.replace(/\\/g, '/'); // 将路径中的反斜杠替换为斜杠
        const downloadLink = `<a href="${logPath}" download>查看质检报告</a>`;
     
        
        const xlspath =  res.xlspath;
        const xlsurl = xlspath.replace(/\\/g, '/'); // 将路径中的反斜杠替换为斜杠
        const downloadLinkxls = `<a href="${xlsurl}" download>下载质检数据【标记红色】</a>`;

        this.$alert(`${downloadLink} | ${downloadLinkxls}<br>${res.message}`, '标准数据模板录入-质检报告', {
          dangerouslyUseHTMLString: true,
          customClass: 'custom-alert',
          style: {
            width: 'auto', // 设置宽度自适应
            maxWidth: '90vw' // 设置最大宽度为视口宽度的90%
          }
        });
      
        }
    },
    onUploadExceed() {
      this.$message.error({
        message: '如需重新上传，请删除已上传文件！'
      })
    },
    onUploadRemove() {}
  },
  mounted() {
    
  }
}
</script>

<style lang="less" scoped>
.upload {
  &-tip {
    margin-top: 10px;
    color: #666;
  }
}
</style>
